import React from "react";

import {Order} from "utils/types/Order";

import styles from "./styles.module.scss";
import {InvoiceOrdersForCustomer} from "components/InvoiceOrdersForCustomer";
import {CustomerWithOrders} from "utils/types/InvoiceSummary";
import {TaskDescriptionIcon} from "assets/images";

type InvoiceOrdersProps = {
  orders: Array<Order>;
  customerOrdersTotalInDollars: string;
  primaryCustomerName: string;
  childCustomers: CustomerWithOrders[];
  setActiveOrderUuid: (uuid: string) => void;
};

export const InvoiceOrders = ({
  orders,
  customerOrdersTotalInDollars,
  primaryCustomerName,
  childCustomers,
  setActiveOrderUuid,
}: InvoiceOrdersProps) => (
  <>
    <p className={styles.summaryTitle}>
      <TaskDescriptionIcon /> Summary
    </p>
    <table>
      <tbody>
        <tr className={styles.invoiceOrdersHeader}>
          <th className={styles.orderNumber}>ORDER #</th>
          <th>INTAKE DATE</th>
          <th>AMOUNT</th>
        </tr>
      </tbody>
    </table>
    <InvoiceOrdersForCustomer
      customerOrdersTotalInDollars={customerOrdersTotalInDollars}
      orders={orders}
      setActiveOrderUuid={setActiveOrderUuid}
      primaryCustomerName={primaryCustomerName}
      withCustomerNameTitle={!!childCustomers.length}
    />
    {childCustomers.map((childCustomer) => (
      <InvoiceOrdersForCustomer
        customerOrdersTotalInDollars={childCustomer.total}
        orders={childCustomer.orders}
        setActiveOrderUuid={setActiveOrderUuid}
        primaryCustomerName={primaryCustomerName}
        customerName={childCustomer.customerName}
        withCustomerNameTitle
      />
    ))}
  </>
);
