import axios, { AxiosError } from "axios";
import * as Sentry from "@sentry/browser";

const httpClient = axios.create({
  baseURL: import.meta.env.VITE_CENTS_API_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    config.headers = {
      "user-session-data": sessionStorage.getItem("sessionData"),
    };
    return config;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    Sentry.setExtra("error", error?.response?.data ?? error);
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

export const query = <T>(url: string) => {
  return async () => {
    const response = await httpClient<T>({ method: "GET", url });
    return response.data;
  };
};

type Mutation = {
  method?: string;
  url: string;
  data?: Record<string, unknown>;
};
export const mutation = <T>({ method = "POST", url, data }: Mutation) => {
  return async () => {
    const response = await httpClient<T>({ method, url, data });
    return response.data;
  };
};
