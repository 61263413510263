import React from "react";

import {Order} from "utils/types/Order";

import styles from "./styles.module.scss";

type InvoiceOrdersForCustomerProps = {
  orders: Array<Order>;
  customerName?: string;
  primaryCustomerName: string;
  customerOrdersTotalInDollars: string;
  withCustomerNameTitle?: boolean;
  setActiveOrderUuid: (uuid: string) => void;
};

export const InvoiceOrdersForCustomer = ({
  orders,
  customerName, // provided only for sub-customers
  customerOrdersTotalInDollars,
  primaryCustomerName,
  withCustomerNameTitle,
  setActiveOrderUuid,
}: InvoiceOrdersForCustomerProps) => (
  <>
    {withCustomerNameTitle && (
      <p className={styles.customerNameTitle}>
        <span className={styles.fontBold}>{customerName || primaryCustomerName}</span>{" "}
        <span className={styles.fontItalic}>
          ({customerName ? `Billing to ${primaryCustomerName}` : `Primary Account`})
        </span>
        : {customerOrdersTotalInDollars}
      </p>
    )}
    {orders.length ? (
      <table>
        <tbody>
          {orders.map((order) => (
            <tr className={styles.invoiceOrdersRow} key={order.orderCodeWithPrefix}>
              <td>
                <p
                  className={styles.orderNumber}
                  onClick={() => setActiveOrderUuid(order.uuid)}
                >
                  {order.orderCodeWithPrefix}
                </p>
              </td>
              <td className={styles.intakeDate}>{order.intakeDate}</td>
              <td>
                <p className={styles.balanceDueNet}>{order.netOrderTotal}</p>
                <p className={styles.balanceDueTaxes}>incl. {order.tax} tax</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className={styles.noOrders}>No orders</p>
    )}
  </>
);
